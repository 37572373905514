import FieldTemplate from "./FieldTemplate"
import {RemoveEvent} from "./types"

type FieldProps = {
    removeEvent: RemoveEvent
}

// todo переписать все остальные по примеру languages
const fieldsMatcher = {
    Languages: (props: FieldProps) => <FieldTemplate name={"Languages"} fieldName={"Languages"} placeholder={"english"} removeEvent={props.removeEvent} />,
    Courses: (props: FieldProps) => <FieldTemplate name={"Courses"} fieldName={"Courses"} placeholder={"Courses"} removeEvent={props.removeEvent} />,
    Links: (props: FieldProps) => <FieldTemplate name={"Links"} fieldName={"Links"} placeholder={"Links"} removeEvent={props.removeEvent} />,
    "Military Service": (props: FieldProps) => <FieldTemplate name={"Military Service"} fieldName={"Military Service"} placeholder={"Military Service"} removeEvent={props.removeEvent} />,
    Volunteering: (props: FieldProps) => <FieldTemplate name={"Volunteering"} fieldName={"Volunteering"} placeholder={"Volunteering"} removeEvent={props.removeEvent} />,
    Hobbies: (props: FieldProps) => <FieldTemplate name={"Hobbies"} fieldName={"Hobbies"} placeholder={"Hobbies"} removeEvent={props.removeEvent} />,
    GDPR: (props: FieldProps) => <FieldTemplate name={"GDPR"} fieldName={"GDPR"} placeholder={"GDPR"} removeEvent={props.removeEvent} />,
    References: (props: FieldProps) => <FieldTemplate name={"References"} fieldName={"References"} placeholder={"References"} removeEvent={props.removeEvent} />,
    Skills: (props: FieldProps) => <FieldTemplate name={"Skills"} fieldName={"Skills"} placeholder={"Skills"} removeEvent={props.removeEvent} />,
    Education: (props: FieldProps) => <FieldTemplate name={"Education"} fieldName={"Education"} placeholder={"Education"} removeEvent={props.removeEvent} />,
    "Work Experience": (props: FieldProps) => <FieldTemplate name={"Work Experience"} fieldName={"Work Experience"} placeholder={"Work Experience"} removeEvent={props.removeEvent} />,
}

export default fieldsMatcher